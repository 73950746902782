<template lang="pug">
  .location-icon
    component(:is="icon")
</template>

<script>
export default {
  props: {
    locationType: {
      required: true,
      type: String
    }
  },
  computed: {
    icon() {
      switch (this.locationType) {
      case 'location':
        return 'icoLocation'
      case 'no-location':
        return 'icoNoLocation'
      default:
        return ''
      }
    }
  },

  components: {
    icoLocation: () => import("@/assets/img/ui/components/IcoLocation.vue"),
    icoNoLocation: () => import("@/assets/img/ui/components/IcoNoLocation.vue")
  }
}
</script>

<style lang="scss">
.location-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: $layout-bg;
  border-radius: 4px;
}
</style>